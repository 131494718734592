import { OrgNode, OrgType } from '@/types/orgChart'

export const courseChart: OrgNode = {
  name: 'First',
  username: '99032',
  title: 'Project M.',
  type: OrgType.partTime,
  children: [
    {
      name: 'First',
      username: '99032',
      title: 'H.Academic',
      type: OrgType.partTime,
      children: [
        {
          name: 'Aom',
          username: '99013',
          title: 'Assistance',
          type: OrgType.fullTime,
          children: [
            {
              name: 'Aom',
              username: '99013',
              title: 'H.Math',
              type: OrgType.fullTime,
              children: [
                {
                  name: 'Pele',
                  username: '99022',
                  title: 'Math Assist',
                  type: OrgType.fullTime,
                  children: [
                    {
                      name: 'BG',
                      username: '99021',
                      title: 'Officer',
                      type: OrgType.fullTime,
                    },
                    {
                      name: 'Bowii',
                      username: '99080',
                      title: 'Officer',
                      type: OrgType.fullTime,
                    },
                    {
                      name: 'Pre',
                      username: '99004',
                      title: 'Officer',
                      type: OrgType.partTime,
                    },
                    {
                      name: 'Bam',
                      username: '99007',
                      title: 'Officer',
                      type: OrgType.partTime,
                    },
                    {
                      name: 'Punch',
                      username: '99066',
                      title: 'Officer',
                      type: OrgType.partTime,
                    },
                    {
                      name: 'Saiparn',
                      username: '99035',
                      title: 'Officer',
                      type: OrgType.partTime,
                    },
                  ],
                },
              ],
            },
            {
              name: 'Chote',
              username: '99012',
              title: 'H.Physics',
              type: OrgType.fullTime,
              children: [
                {
                  name: 'Tao',
                  username: '99023',
                  title: 'Physics Ast.',
                  type: OrgType.fullTime,
                  children: [
                    {
                      name: 'Thep',
                      username: '99076',
                      title: 'Officer',
                      type: OrgType.fullTime,
                    },
                    {
                      name: 'Ken',
                      username: '99077',
                      title: 'Officer',
                      type: OrgType.fullTime,
                    },
                    {
                      name: 'PondC',
                      username: '99050',
                      title: 'Officer',
                      type: OrgType.partTime,
                    },
                    {
                      name: 'Biw',
                      username: '99037',
                      title: 'Officer',
                      type: OrgType.partTime,
                    },
                  ],
                },
              ],
            },
            {
              name: 'BB',
              username: '99003',
              title: 'H.English',
              type: OrgType.fullTime,
              children: [
                {
                  name: 'Ten',
                  username: '99008',
                  title: 'English Ast.',
                  type: OrgType.fullTime,
                  children: [
                    {
                      name: 'Ya',
                      username: '99016',
                      title: 'Officer',
                      type: OrgType.fullTime,
                    },
                    {
                      name: 'Fasai',
                      username: '99082',
                      title: 'Officer',
                      type: OrgType.fullTime,
                    },
                    {
                      name: 'San',
                      username: '99073',
                      title: 'Officer',
                      type: OrgType.freelance,
                    },
                  ],
                },
              ],
            },
            {
              name: 'BB',
              username: '99003',
              title: 'H.Science',
              type: OrgType.fullTime,
              children: [
                {
                  name: 'Putt',
                  username: '99053',
                  title: 'Officer',
                  type: OrgType.freelance,
                },
                {
                  name: 'Non(NCH)',
                  username: '99083',
                  title: 'Officer',
                  type: OrgType.freelance,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'Ten',
      username: '99008',
      title: 'H.Admin',
      type: OrgType.fullTime,
      children: [
        {
          name: 'Nut',
          username: '99024',
          title: 'Admin',
          type: OrgType.fullTime,
        },
      ],
    },
  ],
}
