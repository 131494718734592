import { Vue, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { User } from '@/types/user'

@Component
export default class extends Vue {
  private usersWithImage: Partial<User>[] = []

  public get imageUrl() {
    return (username: string) => {
      const data = this.usersWithImage.find(el => el.username === username)
      if (data && data.profilePicture) {
        return data.profilePicture.signedUrl
      } else {
        return ''
      }
    }
  }

  mounted() {
    this.fetchUserImage()
  }

  private async fetchUserImage() {
    try {
      const res = await this.$apollo.query<{ users: Partial<User>[] }>({
        query: gql`
          query GetUsersWithImg {
            users(position: staff) {
              username
              profilePicture {
                contentType
                signedUrl
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
      })
      this.usersWithImage = res.data.users
    } catch (error) {
      // empty
    }
  }
}
