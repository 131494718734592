










































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop({ default: false }) changeColor!: boolean
  private color = '#FFCA28'
  private colors = [
    '#EF5350',
    '#EC407A',
    '#AB47BC',
    '#7E57C2',
    '#5C6BC0',
    '#42A5F5',
    '#29B6F6',
    '#26C6DA',
    '#26A69A',
    '#9CCC65',
    '#D4E157',
    '#FFEE58',
    '#FFCA28',
    '#8D6E63',
  ]

  @Watch('changeColor')
  onChangeColor() {
    if (this.changeColor) {
      this.randomColor()
    }
  }

  private randomColor() {
    this.color = this.colors[Math.floor(Math.random() * this.colors.length)]
  }
}
