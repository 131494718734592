import { OrgNode, OrgType } from '@/types/orgChart'

export const mainChart: OrgNode = {
  name: 'Mel',
  username: '99001',
  title: 'Director',
  type: OrgType.fullTime,
  children: [
    {
      name: 'GG',
      username: '99002',
      title: 'CEO',
      type: OrgType.fullTime,
      children: [
        {
          name: 'Tao',
          username: '99023',
          title: 'IT Manager',
          type: OrgType.fullTime,
          children: [
            {
              name: 'Non',
              username: '99009',
              title: 'IT Assist.',
              type: OrgType.fullTime,
              children: [
                {
                  name: 'Beam',
                  username: '99063',
                  title: 'IT',
                  type: OrgType.partTime,
                },
                {
                  name: 'Punch',
                  username: '99066',
                  title: 'IT',
                  type: OrgType.partTime,
                },
                {
                  name: 'Saiparn',
                  username: '99035',
                  title: 'IT',
                  type: OrgType.partTime,
                },
              ],
            },
          ],
        },
        {
          name: 'BB',
          username: '99003',
          title: 'Academic M.',
          type: OrgType.fullTime,
          children: [
            {
              name: 'Pele',
              username: '99022',
              title: 'H.Math',
              type: OrgType.fullTime,
              children: [
                {
                  name: 'Aom',
                  username: '99013',
                  title: 'Math Assist.',
                  type: OrgType.fullTime,
                  children: [
                    {
                      name: 'Bowii',
                      username: '99080',
                      title: 'Officer',
                      type: OrgType.fullTime,
                    },
                    {
                      name: 'Mickey',
                      username: '99085',
                      title: 'Officer',
                      type: OrgType.fullTime,
                    },
                    {
                      name: 'Pre',
                      username: '99004',
                      title: 'Officer',
                      type: OrgType.partTime,
                    },
                    {
                      name: 'Bam',
                      username: '99007',
                      title: 'Officer',
                      type: OrgType.partTime,
                    },
                    {
                      name: 'First',
                      username: '99032',
                      title: 'Officer',
                      type: OrgType.partTime,
                    },
                    {
                      name: 'Erng',
                      username: '99068',
                      title: 'Officer',
                      type: OrgType.partTime,
                    },
                    {
                      name: 'Pench',
                      username: '99079',
                      title: 'Officer',
                      type: OrgType.trainee,
                    },
                    {
                      name: 'Bam(APW)',
                      username: '99087',
                      title: 'Officer',
                      type: OrgType.trainee,
                    },
                  ],
                },
              ],
            },
            {
              name: 'Chote',
              username: '99012',
              title: 'H.Physics',
              type: OrgType.fullTime,
              children: [
                {
                  name: 'Thep',
                  username: '99076',
                  title: 'Officer',
                  type: OrgType.fullTime,
                  children: [
                    {
                      name: 'Ken',
                      username: '99077',
                      title: 'Officer',
                      type: OrgType.fullTime,
                    },
                    {
                      name: 'PondC',
                      username: '99050',
                      title: 'Officer',
                      type: OrgType.partTime,
                    },
                    {
                      name: 'Biw',
                      username: '99037',
                      title: 'Officer',
                      type: OrgType.partTime,
                    },
                  ],
                },
              ],
            },
            {
              name: 'BB',
              username: '99003',
              title: 'H.English',
              type: OrgType.fullTime,
              children: [
                {
                  name: 'Ya',
                  username: '99016',
                  title: 'Officer',
                  type: OrgType.fullTime,
                },
                {
                  name: 'Nok',
                  username: '99086',
                  title: 'Officer',
                  type: OrgType.fullTime,
                },
                {
                  name: 'Gun',
                  username: '99078',
                  title: 'Officer',
                  type: OrgType.fullTime,
                },
                {
                  name: 'Fasai',
                  username: '99082',
                  title: 'Officer',
                  type: OrgType.fullTime,
                },
                {
                  name: 'San',
                  username: '99073',
                  title: 'Officer',
                  type: OrgType.freelance,
                },
              ],
            },
            {
              name: 'BB',
              username: '99003',
              title: 'H.Science',
              type: OrgType.fullTime,
              children: [
                {
                  name: 'Putt',
                  username: '99053',
                  title: 'Officer',
                  type: OrgType.freelance,
                },
                {
                  name: 'Non(NCH)',
                  username: '99083',
                  title: 'Officer',
                  type: OrgType.freelance,
                },
                {
                  name: 'Cherry',
                  username: '99084',
                  title: 'Officer',
                  type: OrgType.freelance,
                },
              ],
            },
          ],
        },
        {
          name: 'Pooh',
          username: '99015',
          title: 'Skill Manag.',
          type: OrgType.fullTime,
          children: [
            {
              name: 'Moo',
              username: '99025',
              title: 'Skill Assist.',
              type: OrgType.fullTime,
              children: [
                {
                  name: 'Waew',
                  username: '99027',
                  title: 'Skill',
                  type: OrgType.fullTime,
                },
                {
                  name: 'BK',
                  username: '99014',
                  title: 'Skill',
                  type: OrgType.fullTime,
                },
              ],
            },
          ],
        },
        {
          name: 'BG',
          username: '99021',
          title: 'General M.',
          type: OrgType.fullTime,
          children: [
            {
              name: 'Ten',
              username: '99008',
              title: 'General Ast.',
              type: OrgType.fullTime,
              children: [
                {
                  name: 'Nut',
                  username: '99024',
                  title: 'Officer',
                  type: OrgType.fullTime,
                },
                {
                  name: 'Prim',
                  username: '99071',
                  title: 'Officer',
                  type: OrgType.partTime,
                },
                {
                  name: 'SBT',
                  username: '99081',
                  title: 'Officer',
                  type: OrgType.fullTime,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}
