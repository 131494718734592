import { OrgNode, OrgType } from '@/types/orgChart'

export const hybridChart: OrgNode = {
  name: 'Pre',
  username: '99004',
  title: 'Project M.',
  type: OrgType.partTime,
  children: [
    {
      name: 'Pre',
      username: '99004',
      title: 'H.Academic',
      type: OrgType.partTime,
      children: [
        {
          name: 'Pele',
          username: '99022',
          title: 'Assistance',
          type: OrgType.fullTime,
          children: [
            {
              name: 'Pre',
              username: '99004',
              title: 'H.Math',
              type: OrgType.partTime,
              children: [
                {
                  name: 'Pele',
                  username: '99022',
                  title: 'Math Assist',
                  type: OrgType.fullTime,
                  children: [
                    {
                      name: 'Aom',
                      username: '99013',
                      title: 'Officer',
                      type: OrgType.fullTime,
                    },
                    {
                      name: 'Bowii',
                      username: '99080',
                      title: 'Officer',
                      type: OrgType.fullTime,
                    },
                    {
                      name: 'Mickey',
                      username: '99085',
                      title: 'Officer',
                      type: OrgType.fullTime,
                    },
                    {
                      name: 'BG',
                      username: '99021',
                      title: 'Officer',
                      type: OrgType.fullTime,
                    },
                    {
                      name: 'Bam',
                      username: '99007',
                      title: 'Officer',
                      type: OrgType.partTime,
                    },
                    {
                      name: 'First',
                      username: '99032',
                      title: 'Officer',
                      type: OrgType.partTime,
                    },
                    {
                      name: 'Punch',
                      username: '99066',
                      title: 'Officer',
                      type: OrgType.partTime,
                    },
                    {
                      name: 'Saiparn',
                      username: '99035',
                      title: 'Officer',
                      type: OrgType.partTime,
                    },
                    {
                      name: 'Erng',
                      username: '99068',
                      title: 'Officer',
                      type: OrgType.partTime,
                    },
                    {
                      name: 'Pench',
                      username: '99079',
                      title: 'Officer',
                      type: OrgType.trainee,
                    },
                    {
                      name: 'Bam(APW)',
                      username: '99087',
                      title: 'Officer',
                      type: OrgType.trainee,
                    },
                    {
                      name: 'Non(NCH)',
                      username: '99083',
                      title: 'Officer',
                      type: OrgType.freelance,
                    },
                  ],
                },
              ],
            },
            {
              name: 'Chote',
              username: '99012',
              title: 'H.Physics',
              type: OrgType.fullTime,
              children: [
                {
                  name: 'PondC',
                  username: '99050',
                  title: 'Physics Ast.',
                  type: OrgType.partTime,
                  children: [
                    {
                      name: 'Thep',
                      username: '99076',
                      title: 'Officer',
                      type: OrgType.fullTime,
                    },
                    {
                      name: 'Ken',
                      username: '99077',
                      title: 'Officer',
                      type: OrgType.fullTime,
                    },
                    {
                      name: 'Biw',
                      username: '99037',
                      title: 'Officer',
                      type: OrgType.partTime,
                    },
                  ],
                },
              ],
            },
            {
              name: 'BB',
              username: '99003',
              title: 'H.English',
              type: OrgType.fullTime,
              children: [
                {
                  name: 'Nok',
                  username: '99086',
                  title: 'Officer',
                  type: OrgType.fullTime,
                },
                {
                  name: 'Gun',
                  username: '99078',
                  title: 'Officer',
                  type: OrgType.fullTime,
                },
                {
                  name: 'Fasai',
                  username: '99082',
                  title: 'Officer',
                  type: OrgType.fullTime,
                },
                {
                  name: 'Ten',
                  username: '99008',
                  title: 'Officer',
                  type: OrgType.fullTime,
                },
              ],
            },
            {
              name: 'BB',
              username: '99003',
              title: 'H.Chem',
              type: OrgType.fullTime,
              children: [
                {
                  name: 'Biw',
                  username: '99037',
                  title: 'Officer',
                  type: OrgType.partTime,
                },
                {
                  name: 'Punch',
                  username: '99066',
                  title: 'Officer',
                  type: OrgType.partTime,
                },
                {
                  name: 'Non(NCH)',
                  username: '99083',
                  title: 'Officer',
                  type: OrgType.freelance,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'Ten',
      username: '99008',
      title: 'H.Admin',
      type: OrgType.fullTime,
      children: [
        {
          name: 'Nut',
          username: '99024',
          title: 'Admin',
          type: OrgType.fullTime,
        },
        {
          name: 'Prim',
          username: '99071',
          title: 'Admin',
          type: OrgType.partTime,
        },
      ],
    },
  ],
}
