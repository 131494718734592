export enum OrgType {
  fullTime = 'FullTime',
  partTime = 'PartTime',
  trainee = 'Trainee',
  freelance = 'Freelance',
}

export interface OrgNode {
  name: string
  username: string
  title: string
  type: OrgType
  children?: OrgNode[]
}
