import { OrgNode, OrgType } from '@/types/orgChart'

export const ehbEngChart: OrgNode = {
  name: 'Ten',
  username: '99008',
  title: 'HOP',
  type: OrgType.fullTime,
  children: [
    {
      name: 'BB',
      username: '99003',
      title: 'Academic',
      type: OrgType.fullTime,
      children: [
        {
          name: 'Nok',
          username: '99086',
          title: 'Officer',
          type: OrgType.fullTime,
        },
        {
          name: 'Gun',
          username: '99078',
          title: 'Officer',
          type: OrgType.fullTime,
        },
        {
          name: 'Ya',
          username: '99016',
          title: 'Officer',
          type: OrgType.fullTime,
        },
        {
          name: 'Ten',
          username: '99008',
          title: 'Officer',
          type: OrgType.fullTime,
        },
        {
          name: 'Fasai',
          username: '99082',
          title: 'Officer',
          type: OrgType.fullTime,
        },
      ],
    },
  ],
}
