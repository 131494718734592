








































































import { Mixins, Component } from 'vue-property-decorator'
import OrganizationChart from 'vue-organization-chart'
import 'vue-organization-chart/dist/orgchart.css'
import { OrgNode, OrgType } from '@/types/orgChart'
import ProfileImage from '@/components/Home/ProfileImage'
import { mainChart } from '@/components/Home/MainChart'
import { courseChart } from '@/components/Home/CourseChart'
import { hybridChart } from '@/components/Home/HybridChart'
import { everydayChart } from '@/components/Home/EverydayChart'
import EmployeeCard from '@/components/Home/EmployeeCard.vue'
import { ehbEngChart } from '@/components/Home/EhbEnglish'

@Component({
  components: {
    OrganizationChart,
    EmployeeCard,
  },
})
export default class extends Mixins(ProfileImage) {
  private selectedUser: OrgNode | null = null
  private profileDialog = false
  private charts = ['Main', 'Course', 'Hybrid', 'Everyday', 'EHB(Eng)']
  private selectedChart = 0
  private orgChartSrc: OrgNode = mainChart

  private orgType(type: OrgType) {
    switch (type) {
      case OrgType.fullTime:
        return 'FT'
      case OrgType.partTime:
        return 'PT'
      case OrgType.trainee:
        return 'TN'
      case OrgType.freelance:
        return 'FL'
      default:
        return '-'
    }
  }

  private changeChart(index: number) {
    switch (index) {
      case 1:
        this.orgChartSrc = courseChart
        break
      case 2:
        this.orgChartSrc = hybridChart
        break
      case 3:
        this.orgChartSrc = everydayChart
        break
      case 4:
        this.orgChartSrc = ehbEngChart
        break
      default:
        this.orgChartSrc = mainChart
        break
    }
    this.selectedChart = index
  }

  private nodeClick(el: OrgNode) {
    this.selectedUser = el
    this.profileDialog = true
  }
}
