import { OrgNode, OrgType } from '@/types/orgChart'

export const everydayChart: OrgNode = {
  name: 'Non',
  username: '99009',
  title: 'Project M.',
  type: OrgType.fullTime,
  children: [
    {
      name: 'BG',
      username: '99021',
      title: 'PM Assist.',
      type: OrgType.fullTime,
      children: [
        {
          name: 'Pele',
          username: '99022',
          title: 'H.Academic',
          type: OrgType.fullTime,
          children: [
            {
              name: 'Aom',
              username: '99013',
              title: 'Assistance',
              type: OrgType.fullTime,
              children: [
                {
                  name: 'Pele',
                  username: '99022',
                  title: 'H.Math',
                  type: OrgType.fullTime,
                  children: [
                    {
                      name: 'Aom',
                      username: '99013',
                      title: 'Core C.',
                      type: OrgType.fullTime,
                      children: [
                        {
                          name: 'First',
                          username: '99032',
                          title: 'Officer',
                          type: OrgType.partTime,
                        },
                        {
                          name: 'Erng',
                          username: '99068',
                          title: 'Officer',
                          type: OrgType.partTime,
                        },
                        {
                          name: 'Bam(APW)',
                          username: '99087',
                          title: 'Officer',
                          type: OrgType.trainee,
                        },
                      ],
                    },
                    {
                      name: 'Pele',
                      username: '99022',
                      title: 'Advance C.',
                      type: OrgType.fullTime,
                      children: [
                        {
                          name: 'Bowii',
                          username: '99080',
                          title: 'Officer',
                          type: OrgType.fullTime,
                        },
                        {
                          name: 'Mickey',
                          username: '99085',
                          title: 'Officer',
                          type: OrgType.fullTime,
                        },
                        {
                          name: 'Pre',
                          username: '99004',
                          title: 'Officer',
                          type: OrgType.partTime,
                        },
                        {
                          name: 'Pench',
                          username: '99079',
                          title: 'Officer',
                          type: OrgType.trainee,
                        },
                      ],
                    },
                  ],
                },
                {
                  name: 'Chote',
                  username: '99012',
                  title: 'H.Physics',
                  type: OrgType.fullTime,
                  children: [
                    {
                      name: 'Thep',
                      username: '99076',
                      title: 'Physics Ast.',
                      type: OrgType.fullTime,
                      children: [
                        {
                          name: 'Ken',
                          username: '99077',
                          title: 'Officer',
                          type: OrgType.fullTime,
                        },
                        {
                          name: 'PondC',
                          username: '99050',
                          title: 'Officer',
                          type: OrgType.partTime,
                        },
                        {
                          name: 'Biw',
                          username: '99037',
                          title: 'Officer',
                          type: OrgType.partTime,
                        },
                      ],
                    },
                  ],
                },
                {
                  name: 'BB',
                  username: '99003',
                  title: 'H.English',
                  type: OrgType.fullTime,
                  children: [
                    {
                      name: 'Fasai',
                      username: '99082',
                      title: 'English Ast.',
                      type: OrgType.fullTime,
                      children: [
                        {
                          name: 'Nok',
                          username: '99086',
                          title: 'Officer',
                          type: OrgType.fullTime,
                        },
                        {
                          name: 'Gun',
                          username: '99078',
                          title: 'Officer',
                          type: OrgType.fullTime,
                        },
                      ],
                    },
                  ],
                },
                {
                  name: 'BB',
                  username: '99003',
                  title: 'H.Bio',
                  type: OrgType.fullTime,
                  children: [
                    {
                      name: 'Cherry',
                      username: '99084',
                      title: 'Officer',
                      type: OrgType.freelance,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'BG',
          username: '99021',
          title: 'H.Support',
          type: OrgType.fullTime,
          children: [
            {
              name: 'Ten',
              username: '99008',
              title: 'Support',
              type: OrgType.fullTime,
            },
          ],
        },
      ],
    },
  ],
}
