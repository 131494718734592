





































































import { Mixins, Component, Prop, Watch } from 'vue-property-decorator'
import { OrgNode, OrgType } from '@/types/orgChart'
import ProfileImage from '@/components/Home/ProfileImage'
import gql from 'graphql-tag'
import Bubble from '@/components/Home/Bubble.vue'

interface UserInfo {
  username: string
  firstName: string
  firstNameEn: string
  lastName: string
  lastNameEn: string
  nickname: string
  nicknameEn: string
  email: string
}

@Component({
  components: {
    Bubble,
  },
})
export default class extends Mixins(ProfileImage) {
  @Prop({ default: null }) user!: OrgNode | null
  private onFetch = true
  private userInfo: UserInfo | null = null

  private get userType() {
    if (this.user) {
      switch (this.user.type) {
        case OrgType.fullTime:
          return 'พนักงานประจำ'
        case OrgType.partTime:
          return 'พนักงานพาร์ทไทม์'
        case OrgType.trainee:
          return 'Trainee'
        case OrgType.freelance:
          return 'พนักงานอิสระ'
        default:
          return '-'
      }
    } else {
      return '-'
    }
  }

  @Watch('user', { immediate: true })
  onUserChange() {
    if (this.user) {
      this.fetchUser()
    } else {
      this.userInfo = null
    }
  }

  private async fetchUser() {
    this.onFetch = true
    try {
      const { data } = await this.$apollo.query<{ user: UserInfo }>({
        query: gql`
          query GetThisCardInfo($data: String) {
            user(username: $data) {
              username
              firstName
              firstNameEn
              lastName
              lastNameEn
              nickname
              nicknameEn
              email
            }
          }
        `,
        variables: {
          data: this.user ? this.user.username : '',
        },
        fetchPolicy: 'no-cache',
      })
      this.userInfo = data.user
    } catch (error) {
      this.userInfo = null
    }

    this.onFetch = false
  }
}
